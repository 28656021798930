import create from "zustand";
import { devtools } from "zustand/middleware";

type Ad = {
    name: string;
    url: string;
    imageUrl: string;
    level: { name: "Gold" | "Silver" | "Bronce"; rank: number };
};

interface AdState {
    ads: Ad[];
    initAds: () => Promise<void>;
}

const useAdStore = create<AdState>()(
    devtools((set, get) => ({
        ads: [],
        initAds: async () => {
            const ads = await fetch(
                "https://cdn.contentful.com/spaces/62pbyh8v7mtf/environments/master/entries?content_type=ad&access_token=JV1JLfxkIakkLqKkCiL9zlcoMI_kYpWsAdwioMe0Rf8"
            ).then((response) => response.json());
            const parsedAds = ads.items.map((item: any) => ({
                name: item.fields.name,
                url: item.fields.url,
                imageUrl: getImageUrl(ads, item.fields.image.sys.id),
                level: getLevel(ads, item.fields.level.sys.id),
            }));
            set({ ads: parsedAds });
        },
    }))
);

const getLevel = (ads: any, levelId: string) => {
    const levelEntry = ads.includes.Entry.find(
        (asset: any) => asset.sys.id === levelId
    );
    return {
        name: levelEntry.fields.name,
        rank: levelEntry.fields.rank,
    };
};

const getImageUrl = (ads: any, imageId: string) => {
    return (
        "https:" +
        ads.includes.Asset.find((asset: any) => asset.sys.id === imageId).fields
            .file.url
    );
};

export { useAdStore };
