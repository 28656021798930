import React from "react";
import { useAdStore } from "./adsStore";

type AdProps = {
    rank: number;
};

export const Ad: React.FC<AdProps> = ({ rank }) => {
    const allAds = useAdStore((state) => state.ads);
    const suitableAds = allAds.filter((ad) => {
        return ad.level.rank === rank;
    });

    if (suitableAds.length === 0) {
        return <></>;
    }

    const randomAd =
        suitableAds[Math.floor(Math.random() * suitableAds.length)];

    return (
        <>
            <a href={randomAd.url} target="_blank" rel="noreferrer">
                <img
                    src={randomAd.imageUrl}
                    className={"ad"}
                    alt={randomAd.name}
                ></img>
            </a>
        </>
    );
};

export default Ad;
