import React, { useEffect } from "react";
import { Switch, Route } from "react-router-dom";
import App from "./App";
import Group from "./Group";
import TeamComponent from "./Team";
import { useGroupStore } from "./groupStore";
import { useAdStore } from "./adsStore";

const Router: React.FC = () => {
    const initGroups = useGroupStore((state) => state.initGroups);
    const initAds = useAdStore((state) => state.initAds);
    useEffect(() => {
        initGroups();
        initAds();
    }, [initGroups, initAds]);
    return (
        <Switch>
            <Route path="/" exact={true}>
                <App />
            </Route>
            <Route path="/about" exact={true}>
                <App />
            </Route>
            <Route path="/group/:groupId" exact={true}>
                <Group />
            </Route>
            <Route path="/group/:groupId/team/:teamId" exact={true}>
                <TeamComponent />
            </Route>
        </Switch>
    );
};

export default Router;
