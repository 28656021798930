import { Link } from "react-router-dom";
import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronRight as ChevronRight } from "@fortawesome/free-solid-svg-icons";
import { useFavoriteStore } from "./favoriteStore";
import { useGroupStore } from "./groupStore";

export const Favorties: React.FC = () => {
    const favorites = useFavoriteStore((state) => state.favorites);
    const findById = useGroupStore((state) => state.findById);
    return (
        <>
            <h3>Meine Favoriten</h3>
            {favorites.length > 0 ? (
                <ul className="teams">
                    {favorites.map((element) => (
                        <li key={element.id}>
                            <Link
                                to={`group/${element.groupId}/team/${element.id}`}
                            >
                                {element.team_name}
                                {" - "}
                                {findById(element.groupId).name}
                                <FontAwesomeIcon
                                    icon={ChevronRight}
                                    className="arrow"
                                />
                            </Link>
                        </li>
                    ))}
                </ul>
            ) : (
                <p>
                    Du hast noch keine Favoriten, gehe auf ein Team und drücke
                    auf den Stern um es zu deinen Favoriten hinzuzufügen.
                </p>
            )}
        </>
    );
};
