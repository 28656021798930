import create from "zustand";
import { devtools } from "zustand/middleware";

type Group = {
    id: number;
    name: string;
    typ: string;
};

interface GroupState {
    groups: Group[];
    findById: (id: number) => Group;
    initGroups: () => Promise<void>;
}

const useGroupStore = create<GroupState>()(
    devtools((set, get) => ({
        groups: [],
        findById: (id) => {
            let filtered = get().groups.filter((group) => group.id === id);
            return (
                filtered[0] ?? {
                    id: 0,
                    name: "",
                    typ: "",
                }
            );
        },
        initGroups: async () => {
            let groups = await fetch(
                process.env.REACT_APP_API_URL + `ws/groupsV1.php`
            ).then((response) => response.json());
            groups = groups.map(
                (g: { group: { ID: string; name: string; typ: string } }) => {
                    return {
                        id: parseInt(g.group.ID),
                        name: g.group.name,
                        typ: g.group.typ,
                    };
                }
            );
            set({ groups });
        },
    }))
);

export { useGroupStore };
