import { Link } from "react-router-dom";

function Header() {
    return (
        <>
            <header>
                <div />
                <h1>Korbball App</h1>
                <div className="menu" style={{ right: "1em" }}>
                    <Link to={"/"}>Alle Ligen</Link>
                </div>
            </header>
        </>
    );
}
export default Header;
