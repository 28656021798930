import React, { useEffect, useState } from "react";
import Header from "./Header";
import { Link, useParams } from "react-router-dom";
import Footer from "./footer";
import { RankingTable } from "./RankingTable";
import { Game } from "./Game";
import { Team } from "./Team";
import { useGroupStore } from "./groupStore";
import { Ad } from "./Ad";

let Group: React.FC;

Group = () => {
    const { groupId } = useParams<{ groupId: string }>();
    const [group, setGroup] = useState({
        id: 0,
        name: "",
        typ: "",
    });
    const [teams, setTeams] = useState<Team[]>([]);
    const [games, setGames] = useState([
        {
            tag: "0",
            txtTeamA: "",
            resultatA: "",
            resultatB: "",
            txtTeamB: "",
            zeit: "",
            runde: "",
            halle: "",
        },
    ]);

    const findById = useGroupStore((state) => state.findById);

    useEffect(() => {
        async function fetchGroupName() {
            const group = findById(parseInt(groupId));
            setGroup(group);
        }

        fetchGroupName();
    }, [groupId, findById]);

    useEffect(() => {
        async function fetchTeams() {
            const teamsResponse = await fetch(
                process.env.REACT_APP_API_URL +
                    `ws/rankingV1.php?group=${groupId}`
            ).then((response) => response.json());

            const teams: any[] = [];

            Object.keys(teamsResponse).forEach(function (key: any) {
                teams.push(teamsResponse[key]);
            });

            setTeams(teams);
        }

        fetchTeams();
    }, [groupId]);

    useEffect(() => {
        async function fetchGames() {
            const games = await fetch(
                process.env.REACT_APP_API_URL +
                    `ws/gamesV1.php?group=${groupId}`
            ).then((response) => response.json());
            setGames(games);
        }

        fetchGames();
    }, [groupId]);

    return (
        <div className="App">
            <Header />
            <div className="App-container">
                <h2>{group.name}</h2>
                {group.typ !== "cup" && (
                    <RankingTable teams={teams} groupId={groupId || ""} />
                )}
                <Link className="tabelle_link" to={"/"}>
                    Alle Gruppen &gt;
                </Link>
                <Ad rank={2}/>
                {games.length === 0 && (
                    <p>
                        Für diese Gruppe sind noch keine Spiele eingetragen.{" "}
                        <Link className="" to={"/"}>
                            Zurück zur Übersicht
                        </Link>
                    </p>
                )}
                {games.length > 0 && (
                    <>
                        <Link className="tabelle_link" to={"/"}>
                            Alle Ligen &gt;
                        </Link>
                        <h3>Spiele</h3>
                        <table className="games table">
                            <tbody>
                                {games.map((game) => (
                                    <Game
                                        key={`${game.tag}_${game.zeit}_${game.halle}`}
                                        game={game}
                                    />
                                ))}
                            </tbody>
                        </table>
                        <br />
                    </>
                )}
            </div>
            <Footer />
        </div>
    );
};

export default Group;
