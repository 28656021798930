import React, { useEffect, useState } from "react";
import Header from "./Header";
import { Link, useParams } from "react-router-dom";
import Footer from "./footer";
import { RankingTable } from "./RankingTable";
import { Game } from "./Game";
import { useFavoriteStore } from "./favoriteStore";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faStar as solidStar } from "@fortawesome/free-solid-svg-icons";
import { faStar as regularStar } from "@fortawesome/free-regular-svg-icons";
import { useGroupStore } from "./groupStore";
import { Ad } from "./Ad";

export interface Team {
    teamId: number;
    team: string;
    points: number;
    games: number;
    played: number;
    won: number;
    lost: number;
    tie: number;
    rank: number;
    rate: [number, number];
}

const TeamComponent: React.FC = () => {
    const { teamId, groupId } = useParams<{
        teamId: string;
        groupId: string;
    }>();
    const addFavorite = useFavoriteStore((state) => state.add);
    const removeFavorite = useFavoriteStore((state) => state.remove);
    const favorites = useFavoriteStore((state) => state.favorites);
    const isFavorite =
        favorites.filter((f) => f.id === parseInt(teamId)).length > 0;

    const [games, setGames] = useState([
        {
            tag: "0",
            txtTeamA: "",
            resultatA: "",
            resultatB: "",
            txtTeamB: "",
            zeit: "",
            runde: "",
            halle: "",
        },
    ]);

    const [teams, setTeams] = useState<Team[]>([]);
    const [current, setCurrent] = useState<Team | undefined>(undefined);
    const findById = useGroupStore((state) => state.findById);

    useEffect(() => {
        async function fetchGames() {
            const games = await fetch(
                process.env.REACT_APP_API_URL + `ws/gamesV1.php?team=${teamId}`
            ).then((response) => response.json());
            setGames(games);
        }

        const group = findById(parseInt(groupId));
        console.log(group);

        fetchGames();
    }, [teamId, groupId, findById]);

    const next_games = games.filter((game) => game.resultatA === "");
    const played_games = games.filter((game) => game.resultatA !== "");

    useEffect(() => {
        async function fetchTeams() {
            const teamsResponse = await fetch(
                process.env.REACT_APP_API_URL +
                    `ws/rankingV1.php?group=${groupId}`
            ).then((response) => response.json());
            const teams: any[] = [];

            Object.keys(teamsResponse).forEach(function (key: any) {
                teams.push(teamsResponse[key]);
            });

            if (teamId) {
                setCurrent(
                    teams.filter((g) => g.teamId === parseInt(teamId))[0]
                );
            }

            setTeams(teams);
        }

        fetchTeams();
    }, [groupId, teamId]);

    var neighbourhood = teams;
    if (current) {
        neighbourhood = teams.filter((t) => {
            const abs = Math.abs(t.rank - current.rank);

            if (current.rank === 1 || current.rank === teams.length) {
                return abs <= 2;
            }
            return abs <= 1;
        });
    }

    function toggleFavorite(id?: string) {
        if (id && groupId && current) {
            const favoriteTeam = {
                id: parseInt(id),
                groupId: parseInt(groupId),
                team_name: current.team,
            };
            if (isFavorite) {
                removeFavorite(favoriteTeam);
            } else {
                addFavorite(favoriteTeam);
            }
        }
    }

    return (
        <div className="App">
            <Header />
            <div className="App-container">
                <h2>
                    {current && current.team}
                    <span
                        className="star"
                        onClick={() => toggleFavorite(teamId)}
                    >
                        {isFavorite ? (
                            <FontAwesomeIcon
                                icon={solidStar}
                                title="Aus Favoriten entfernen"
                            />
                        ) : (
                            <FontAwesomeIcon
                                icon={regularStar}
                                title="Zum Favoriten machen"
                            />
                        )}
                    </span>
                </h2>
                <RankingTable
                    teams={neighbourhood}
                    groupId={groupId || ""}
                    showGroupName={true}
                />
                <Link className="tabelle_link" to={"/group/" + groupId}>
                    Alle ansehen &gt;
                </Link>
                {played_games.length > 0 && (
                    <>
                        <h3>Letzte Spiele</h3>
                        <table className="games table">
                            <tbody>
                                {played_games.map((game) => (
                                    <Game
                                        key={`${game.tag}_${game.zeit}_${game.halle}`}
                                        game={game}
                                    />
                                ))}
                            </tbody>
                        </table>
                        <br />
                    </>
                )}
                <Ad rank={3}/>
                {next_games.length > 0 && (
                    <>
                        <h3>Nächste Spiele</h3>
                        <table className="games table">
                            <tbody>
                                {next_games.map((game) => (
                                    <Game
                                        key={`${game.tag}_${game.zeit}_${game.halle}`}
                                        game={game}
                                    />
                                ))}
                            </tbody>
                        </table>
                    </>
                )}
                <p></p>
                <ul></ul>
            </div>
            <Footer />
        </div>
    );
};

export default TeamComponent;
