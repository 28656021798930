import React from "react";
var moment = require('moment');

function Footer() {
    return (
        <footer className="App-container">
            <p>2019 - {moment().year()} | Roman Schaller & Samuel Schwegler</p>
        </footer>
    );
}
export default Footer;