import { Link } from "react-router-dom";
import React from "react";
import { Team } from "./Team";
import { useGroupStore } from "./groupStore";

type RankingTableProps = {
    teams: Team[];
    groupId: string;
    showGroupName?: boolean;
};

export const RankingTable: React.FC<RankingTableProps> = ({
    teams,
    groupId,
    showGroupName,
}) => {
    const findGroupById = useGroupStore((state) => state.findById);
    return (
        <>
            <h3>
                Tabelle{" "}
                {showGroupName && `${findGroupById(parseInt(groupId)).name}`}
            </h3>
            <table className="ranking">
                <thead>
                    <tr>
                        <th></th>
                        <th></th>
                        <th>Sp.</th>
                        <th>S</th>
                        <th>U</th>
                        <th>N</th>
                        <th>Körbe</th>
                        <th>+/-</th>
                        <th>Pkt.</th>
                    </tr>
                </thead>
                <tbody>
                    {teams.map((element) => (
                        <tr key={"key_" + element.teamId}>
                            <td>{element.rank}</td>
                            <td className="team">
                                <Link
                                    to={
                                        "/group/" +
                                        groupId +
                                        "/team/" +
                                        element.teamId
                                    }
                                >
                                    {element.team}
                                </Link>
                            </td>
                            <td>{element.played}</td>
                            <td>{element.won}</td>
                            <td>{element.tie}</td>
                            <td>{element.lost}</td>
                            <td className="rate">
                                <span>{element.rate[0]}</span>:
                                <span>{element.rate[1]}</span>
                            </td>
                            <td>{element.rate[0] - element.rate[1]}</td>
                            <td>{element.points}</td>
                        </tr>
                    ))}
                </tbody>
            </table>
        </>
    );
};
