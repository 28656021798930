import React, {Component} from "react";
import Moment from "react-moment";

let previous_game_tag = '1970-01-01';

export class Game extends Component<{ game: any }> {

    render() {
        let {game} = this.props;
        if (!game) {
            return null;
        }

        let output = (
            <>
                <tr className={"game_info " + (game.tag !== previous_game_tag && "new_date")}
                    key={"key_date_" + game.tag + game.zeit}>
                    <td>
                        {game.tag !== previous_game_tag && <>
                            {game.tag !== null && <><Moment format="DD.MM.YYYY">{game.tag}</Moment> {game.runde}</>}
                            {game.tag === null && <>Datum unbekannt</>}
                        </>}
                    </td>
                    <td>{game.zeit !== null && game.zeit.substr(0, 5)}</td>
                    <td>{game.halle}</td>
                </tr>
                <tr className="game_results" key={"key_" + game.tag + game.zeit}>
                    <td className="team">{game.txtTeamA}</td>
                    <td className="result">
                            <span>
                                <span className="number">{game.resultatA}</span> : <span
                                className="number">{game.resultatB}</span>
                            </span>
                    </td>
                    <td className="team">{game.txtTeamB}</td>
                </tr>
            </>
        );

        previous_game_tag = game.tag;

        return output;
    }
}
