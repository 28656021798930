import React, {useEffect, useState} from 'react';
import Header from "./Header";
import {Link} from 'react-router-dom';
import Footer from "./footer";
import {faChevronRight as ChevronRight} from '@fortawesome/free-solid-svg-icons';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {Favorties} from "./Favorites";
import Ad from "./Ad";

interface Group {
    group: { ID: string, name: string }
}

const App: React.FC = () => {
    const [loaded, setLoaded] = useState(false);
    const [groups, setGroups] = useState<Group[]>([]);
    const [cups, setCups] = useState<Group[]>([]);

    useEffect(() => {
        const start = Date.now();

        fetch(process.env.REACT_APP_API_URL + 'ws/groupsV1.php').then(response => response.json())
            .then((groups) => {

                // todo remove old groups method
                groups.sort(function (a: any, b: any) {
                    return (a.group.name > b.group.name) ? 1 : -1;
                })

                setCups(groups.filter((g: { group: { typ: string; }; }) => g.group.typ === "cup"));
                setGroups(groups.filter((g: { group: { typ: string; }; }) => g.group.typ !== "cup"));
            })
            .catch((error) => {
                // handle your errors here
                console.error(error)
            }).finally(() => {
            setTimeout(() => {
                setLoaded(true);
            }, 1200 - (Date.now() - start)) // minimum loading time 1.2s
        });
    }, []);

    return (
        <div className="App">
            <Header/>
            <div className="App-container">
                {!loaded && <>
                    laden...
                </>}
                {groups.length > 1 && <>
                    <Favorties/>
                    <h3>Alle Ligen</h3>
                    <ul className="teams">
                        {groups.map(element => <li key={element.group.ID}>
                            <Link to={`group/${element.group.ID}`}>{element.group.name}
                                <FontAwesomeIcon icon={ChevronRight} className="arrow"/>
                            </Link>
                        </li>)}
                    </ul>
                </>}
                {groups.length === 0 && loaded && <p>
                    Momentan sind keine Ligen verfügbar... Sie sollten beim Saisonstart wieder da sein.
                </p>}
                {cups.length > 1 && <>
                    <h3>Cup</h3>
                    <ul className="teams">
                        {cups.map(element => <li key={element.group.ID}>
                            <Link to={`group/${element.group.ID}`}>{element.group.name}
                                <FontAwesomeIcon icon={ChevronRight} className="arrow"/>
                            </Link>
                        </li>)}
                    </ul>
                </>}
                <Ad rank={3}/>
            </div>
            <Footer/>
        </div>
    );
}

export default App;
