import create from "zustand";
import { devtools, persist } from "zustand/middleware";

type Favorite = {
    id: number;
    groupId: number;
    team_name: string;
};

interface FavoriteState {
    favorites: Favorite[];
    add: (favorite: Favorite) => void;
    remove: (favorite: Favorite) => void;
}

const getFavorites = (): Favorite[] | false => {
    let favorites_str = localStorage.getItem("favorites");
    if (favorites_str != null) {
        return favorites_str ? JSON.parse(favorites_str) : false;
    }

    return false;
};

const fixFavorites = async (favorites: Favorite[]) => {
    const favoriteNames = [...new Set(favorites.map((fav) => fav.team_name))];
    const requestOptions = {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({ favorites: favoriteNames }),
    };

    console.log("request options", requestOptions);

    const fixedFavorites = await fetch(
        process.env.REACT_APP_API_URL + `ws/favoritesV1.php`,
        requestOptions
    ).then((response) => response.json());
    return fixedFavorites;
};

const useFavoriteStore = create<FavoriteState>()(
    devtools(
        persist(
            (set, get) => ({
                favorites: [],
                add: (favorite) =>
                    set((state) => {
                        return { favorites: [...state.favorites, favorite] };
                    }),
                remove: (favorite) =>
                    set((state) => {
                        return {
                            favorites: state.favorites.filter(
                                (f) => f.id !== favorite.id
                            ),
                        };
                    }),
            }),
            {
                name: "favorite-storage",
                deserialize: async (storedValue) => {
                    let oldFavorites = getFavorites();
                    if (oldFavorites) {
                        localStorage.removeItem("favorites");
                        return {
                            version: 0,
                            state: {
                                favorites: await fixFavorites(oldFavorites),
                            },
                        };
                    } else {
                        const storedValueAsJSON = JSON.parse(storedValue);
                        const state = storedValueAsJSON.state;
                        console.log("state", state);
                        const fixedFavorites = await fixFavorites(
                            state.favorites
                        );
                        state.favorites = fixedFavorites;
                        console.log("return", state);
                        return { version: 0, state };
                    }
                },
            }
        )
    )
);

export { useFavoriteStore, fixFavorites };
